var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-app-bar',{attrs:{"dark":"","app":"","color":"primary"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('TermTabs',{attrs:{"align-with-title":""},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}}),_c('v-btn',{attrs:{"to":{ name: 'DispensationAdd' },"absolute":"","bottom":"","right":"","color":"success","fab":""}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}])},[_c('v-app-bar-nav-icon',[_c('v-icon',[_vm._v("mdi-account-remove")])],1),_c('v-toolbar-title',[_vm._v("Dispensationen")]),_c('v-spacer'),_c('SearchField',{attrs:{"hint":"Name oder Klasse"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.itemsFiltered,"sort-by":'startDate',"item-class":() => 'clickable'},on:{"click:row":(item) =>
          _vm.$router.push({
            name: 'DispensationEdit',
            params: {
              id: item.id,
            },
          })},scopedSlots:_vm._u([{key:"item.student",fn:function({ item }){return [_c('PersonItem',{attrs:{"small":"","value":item.student}})]}},{key:"item.start",fn:function({ item }){return [_c('DateValue',{attrs:{"value":item.startDate}}),_vm._v(", "),(item.startTime)?_c('span',[_vm._v(" "+_vm._s(item.startTime))]):_c('span',[_vm._v("morgens")])]}},{key:"item.end",fn:function({ item }){return [_c('DateValue',{attrs:{"value":item.endDate}}),_vm._v(", "),(item.endTime)?_c('span',[_vm._v(" "+_vm._s(item.endTime))]):_c('span',[_vm._v("abends")])]}},{key:"item.physicalEducation",fn:function({ item }){return [_c('v-simple-checkbox',{attrs:{"value":item.physicalEducation,"readonly":""}})]}}])})],1),_c('router-view',{attrs:{"term":_vm.term},on:{"update":_vm.fetchData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }